import {
    Box,
    Code,
    Grid,
    GridItem,
    Heading,
    Link,
    List,
    ListItem,
    OrderedList,
    UnorderedList,
    useColorModeValue,
    Kbd,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
} from "@chakra-ui/react";
import { CodeBlock } from "../components/CodeBlock";
import { Link as ReactRouterLink } from "react-router-dom";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import { ColorModeSwitcherLocation } from "../Common";

export const DocumentationPage = () => {
    const background = useColorModeValue("white.200", "gray.900");

    const tablesCode = `\\begin{array}{|c|c|}
    \\text{Column 1} & \\text{Column 2} \\\\
    \\hline
    (0,0) & (0, 1) \\\\
    (1,0) & (1, 1)
\\end{array}`;

    const tablesCodeSmall = `\\small
\\begin{array}{|c|c|}
    \\text{Column 1} & \\text{Column 2} \\\\
    \\hline
    (0,0) & (0, 1) \\\\
    (1,0) & (1, 1)
\\end{array}
`;

    const proofCode = `\\begin{aligned}
    p \\wedge (p \\rightarrow q) \\equiv && p \\wedge (\\neg p \\vee q) && \\text{Implication} \\\\
    \\equiv && (p \\wedge \\neg p) \\vee (p \\wedge q) && \\text{Distributivity} \\\\
    \\equiv && F \\vee (p \wedge q) && \\text {Negation} \\\\
    \\equiv && p \\wedge q && \\text{Identity}
\\end{aligned}
`;

    const secondProofCode = `\\begin{array}{rll}
    1. & p \\oplus q & \\text{Given} \\\\
    2. & s \\oplus q & \\text{Given} \\\\
    3. & (p \\vee q) \\wedge (\\neg p \\vee \\neg q) & \\text{Definition of } \\oplus : 1 \\\\ 
    4. & (s \\vee q) \\wedge (\\neg s \\vee \\neg q) & \\text{Definition of } \\oplus : 2 \\\\
    5. & \\neg s \\vee \\neg q & \\text{Elim } \\wedge : 4 \\\\
    6. & p \\vee q & \\text{Elim } \\wedge : 3 \\\\
    7. & s \\rightarrow \\neg q & \\text{Implication: 5} \\\\
    8. & q \\vee p & \\text{Commutativity: 6} \\\\
    9. & \\neg q \\rightarrow p & \\text{Implication: 8} \\\\[10pt]
    
    & 10.1 \\ \\ \\ s & \\text{Assumption} \\\\
    & 10.2 \\ \\ \\ \\neg q & \\text{Modus Ponens: 7, 10.1} \\\\
    & 10.3 \\ \\ \\ p & \\text{Modus Ponens: 9, 10.2} \\\\[10pt]
    
    11. & s \\rightarrow p & \\text{Direct Proof Rule}
\\end{array}
`;

    const bulletCode = `\\bullet`;
    const bulletText = " First bullet point";

    const codeBlockCode = `\\texttt{HelloWorld}`;

    const numberedListsCode = `1.
2.
`;

    const letteredListsCode = `a)
b)
`;

    return (
        <Grid
            templateColumns={{ base: "1fr", md: "250px 1fr" }}
            gap={6}
            p={8}
            w="90%"
        >
            {/* Left Sidebar */}
            <GridItem>
                <ColorModeSwitcher
                    location={ColorModeSwitcherLocation.EDITOR}
                />
                <Box as="nav" position="sticky" top="4">
                    <Heading as="h4" size="sm" mt={6} mb={4}>
                        <Link as={ReactRouterLink} to={"/"}>
                            Parchmynt Home
                        </Link>
                    </Heading>

                    <Heading as="h4" size="sm" mt={6} mb={4}>
                        <Link href="#basics">The Basics</Link>
                    </Heading>
                    <List spacing={2}>
                        <ListItem pl={4}>
                            <Link href="#shortcuts">Shortcuts</Link>
                        </ListItem>
                    </List>

                    <Heading as="h4" size="sm" mt={6} mb={4}>
                        <Link href="#latex-constructs">Latex Constructs</Link>
                    </Heading>
                    <List spacing={2}>
                        <ListItem pl={4}>
                            <Link href="#tables">Tables</Link>
                        </ListItem>
                        <ListItem pl={4}>
                            <Link href="#proofs">Proofs</Link>
                        </ListItem>
                        <ListItem pl={4}>
                            <Link href="#images">Images</Link>
                        </ListItem>
                        <ListItem pl={4}>
                            <Link href="#code">Code</Link>
                        </ListItem>
                        <ListItem pl={4}>
                            <Link href="#bulleted-lists">Bulleted Lists</Link>
                        </ListItem>
                        <ListItem pl={4}>
                            <Link href="#numbered-lists">Numbered Lists</Link>
                        </ListItem>
                    </List>
                </Box>
            </GridItem>

            {/* Main Content */}
            <GridItem>
                <Heading as="h1" size="lg" mb={2} id="basics">
                    Documentation
                </Heading>
                <Box mb={6}>
                    This page details how to use the Parchmynt Editor. Please
                    note that:
                    <UnorderedList ml={10}>
                        <ListItem>
                            Parchmynt editor is in <strong>beta</strong>, and
                            has a lot of small bugs that will be fixed over
                            time.
                        </ListItem>
                        <ListItem>
                            The Parchmynt editor works best in{" "}
                            <strong>Google Chrome</strong> (or other chromium
                            based browsers).
                        </ListItem>
                    </UnorderedList>
                    <br />
                    Feel free to notify us about bugs and provide feedback! See
                    instructions on our{" "}
                    <Link
                        as={ReactRouterLink}
                        to={"/support"}
                        isExternal
                        textDecor="underline"
                        textColor="blue.400"
                    >
                        Support Page
                    </Link>
                    .
                </Box>
                <Heading as="h1" size="lg" mb={2} id="basics">
                    The Basics
                </Heading>
                <Box mb={6}>
                    The best way to understand the basics of the editor is to
                    see it in action. Click on this link to watch a short{" "}
                    <Link
                        as={ReactRouterLink}
                        to={"https://www.youtube.com/watch?v=Bd_GegyzUEY"}
                        isExternal
                        textDecor="underline"
                        textColor="blue.400"
                    >
                        YouTube video
                    </Link>{" "}
                    that will walk you through the editor's use.
                    <br />
                    Here is a quick summary of the editor's Latex features:
                    <UnorderedList ml={10}>
                        <ListItem>
                            <strong>L</strong> - this button inserts a Dynamic
                            Latex block inline into your document. Some Latex
                            constructs (for example, <Code>\blacksquare</Code>)
                            will not compile in these blocks.
                        </ListItem>
                        <ListItem>
                            <strong>VL</strong> - this button inserts a Vanilla
                            Latex block inline into your document.
                        </ListItem>
                        <ListItem>
                            <strong>LP</strong> - this button inserts a Latex
                            Paragraph into your document. Latex Paragraphs are
                            designed to be used for large chunks of contiguous
                            Latex, especially for Latex environments (statements
                            that start with <Code>\begin</Code>). Latex
                            Paragraphs cannot span across multiple pages.
                        </ListItem>
                    </UnorderedList>
                    <br />
                    <Heading as="h4" size="md" mb={2} id="shortcuts">
                        Shortcuts
                    </Heading>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Action</Th>
                                    <Th>Shortcut</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>Insert new Dynamic Latex block</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>L</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>L</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Insert new Vanilla Latex block</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>K</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>K</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Bold</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>B</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>B</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Italic</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>I</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>I</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Underline</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>U</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>U</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Strikethrough</Td>
                                    <Td>
                                        <Kbd>Shift</Kbd> + <Kbd>Command</Kbd> +{" "}
                                        <Kbd>X</Kbd> <strong>OR</strong>{" "}
                                        <Kbd>Shift</Kbd> + <Kbd>Control</Kbd> +{" "}
                                        <Kbd>X</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Clear Formatting</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>\</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>\</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Undo</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>Z</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>Z</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Redo</Td>
                                    <Td>
                                        <Kbd>Shift</Kbd> + <Kbd>Command</Kbd> +{" "}
                                        <Kbd>Z</Kbd> <strong>OR</strong>{" "}
                                        <Kbd>Shift</Kbd> + <Kbd>Control</Kbd> +{" "}
                                        <Kbd>Z</Kbd> <strong>OR</strong>{" "}
                                        <Kbd>Control</Kbd> + <Kbd>Y</Kbd>{" "}
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Select All</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>A</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>A</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Cut</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>X</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>X</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Copy</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>C</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>C</Kbd>
                                    </Td>
                                </Tr>
                                <Tr>
                                    <Td>Paste</Td>
                                    <Td>
                                        <Kbd>Command</Kbd> + <Kbd>V</Kbd>{" "}
                                        <strong>OR</strong> <Kbd>Control</Kbd> +{" "}
                                        <Kbd>V</Kbd>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>

                <Heading as="h1" size="lg" mb={2} id="latex-constructs">
                    Latex Constructs
                </Heading>
                <Box mb={6}>
                    The rest of this page details specific constructs (Latex and
                    non-Latex) that you may need to use when writing a document.
                </Box>

                <Heading as="h2" size="lg" mb={2} id="tables">
                    Tables
                </Heading>
                <Box mb={6}>
                    To write a table in Parchmynt's editor, do the following:
                    <OrderedList ml={10}>
                        <ListItem>
                            Create a new Latex Paragraph by clicking the LP
                            button.
                        </ListItem>
                        <ListItem>
                            Inside the Latex Paragraph, the following code
                            should do the trick.
                        </ListItem>
                    </OrderedList>
                    <CodeBlock code={tablesCode} />
                    If the table is too big, you can make it smaller with the
                    following:
                    <CodeBlock code={tablesCodeSmall} />
                    To make it even smaller, replace <Code>
                        \small
                    </Code> with <Code>\scriptsize</Code>.
                </Box>

                <Heading as="h2" size="lg" mb={2} id="proofs">
                    Proofs
                </Heading>
                <Box mb={6}>
                    You can write several different types of Proofs using the
                    Parchmynt editor. Listed here are a few common ones. Note:
                    Just like{" "}
                    <Link
                        href="#tables"
                        textDecor="underline"
                        textColor="blue.400"
                    >
                        tables
                    </Link>
                    , proofs can be made smaller with the <Code>\small</Code> or{" "}
                    <Code>\scriptsize</Code>
                    <br />
                    <br />
                    <Heading as="h3" size="md" mb={2}>
                        One Type of Proof
                    </Heading>
                    <OrderedList ml={10}>
                        <ListItem>
                            Create a new Latex Paragraph by clicking the LP
                            button.
                        </ListItem>
                        <ListItem>
                            Inside the Latex Paragraph, the following code
                            should do the trick.
                        </ListItem>
                    </OrderedList>
                    <CodeBlock code={proofCode} />
                    <br />
                    <br />
                    <Heading as="h3" size="md" mb={2}>
                        Another Type of Proof
                    </Heading>
                    Here is an example of another type of proof, which can be
                    pasted into a Latex Paragraph:
                    <CodeBlock code={secondProofCode} />
                    <br />
                    Something to note about the proof above: Parchmynt's editor
                    does not support <Code>\multicolumn</Code>. Because of this,
                    the above proof uses a few workarounds to get the appearance
                    of a table within a table.
                    <OrderedList ml={10}>
                        <ListItem>
                            The proof consists of a single table (array) that
                            has 3 columns. For the main part of the proof (steps
                            1-9, and 11) the table structure is normal:{" "}
                            <Code>X & Y & Z \\</Code>. However for steps
                            10.1-10.3, We use a trick to keep the first column
                            of the main table blank, giving the appearance that
                            the 10.X numbers are indented:{" "}
                            <Code>& X Y & & Z \\</Code>.
                        </ListItem>
                        <ListItem>
                            Additional separation in the smaller table is
                            created with spaces (<Code>\ \ \</Code>). Each{" "}
                            <Code>\</Code> represents a space. This brings the
                            structure to: <Code>& X \ \ \ Y & Z \\</Code>.
                        </ListItem>
                        <ListItem>
                            <Code>[10pt]</Code> controls the height of the
                            newline (<Code>\\</Code>). We could do this with two
                            newlines (<Code>\\ \\</Code>), but this adds too
                            much space. You can change <Code>[10pt]</Code> to
                            <Code>[5pt]</Code> for example, to further lessen
                            the height of the newline.
                        </ListItem>
                    </OrderedList>
                    <br />
                    <br />
                    <Heading as="h3" size="md" mb={2}>
                        Yet Another Type of Proof (An English Proof)
                    </Heading>
                    In parchment, English proofs are by far the easiest to work
                    with, and allow for the greatest opportunity to leverage the
                    editor's unique features. Here is how to write one:
                    <OrderedList ml={10}>
                        <ListItem>
                            In a normal text environment (not a Latex Paragraph
                            or Latex Block) italicize the text and write
                            “Proof.”
                        </ListItem>
                        <ListItem>
                            Write out the proof using a combination of text,
                            Dynamic Latex Blocks, and Vanilla Latex Blocks.
                        </ListItem>
                        <ListItem>
                            At the end of the proof, create a new Vanilla Latex
                            Block, and type <Code>\blacksquare</Code> inside it.
                            Hit “Tab” a bunch of times to move this block to the
                            right side of the proof.
                        </ListItem>
                    </OrderedList>
                    <Box marginLeft={"5rem"} width="500px">
                        <video src="/EnglishProof.mp4" autoPlay loop muted />
                    </Box>
                </Box>

                <Heading as="h2" size="lg" mb={2} id="images">
                    Images
                </Heading>
                <Box mb={6}>
                    Images are not currently supported in the Parchmynt editor.
                    If you need to include an image in your document, hit
                    “Enter” a bunch of times to create the space for the image.
                    Then, download your Parchmynt document as a PDF, and use
                    your computer's native PDF editor to add the image to your
                    document. Here are instructions on how to do this for a{" "}
                    <Link
                        as={ReactRouterLink}
                        to={"https://www.youtube.com/watch?v=dDJmLNj00n8"}
                        isExternal
                        textDecor="underline"
                        textColor="blue.400"
                    >
                        Mac
                    </Link>
                    . On a PC, you will have to use Adobe Acrobat or an{" "}
                    <Link
                        as={ReactRouterLink}
                        to={"https://www.youtube.com/watch?v=6JDMBQC0vik"}
                        isExternal
                        textDecor="underline"
                        textColor="blue.400"
                    >
                        online PDF editor
                    </Link>
                    .
                </Box>

                <Heading as="h2" size="lg" mb={2} id="code">
                    Code
                </Heading>
                <Box mb={6}>
                    A much better way to write code in the Parchmynt editor is
                    coming soon. For now, you can format text was code within
                    Latex (Vanilla Latex Blocks or Latex Paragraphs) as follows:
                    <CodeBlock code={codeBlockCode} />
                </Box>

                <Heading as="h2" size="lg" mb={2} id="bulleted-lists">
                    Bulleted Lists
                </Heading>
                <Box mb={6}>
                    Bullets are currently not supported natively in the
                    Parchmynt editor. However, you can still use Bullets, by
                    leveraging LaTeX like so:
                    <OrderedList ml={10}>
                        <ListItem>
                            Create a new Vanilla Latex Block by clicking the VL
                            button.
                        </ListItem>
                        <ListItem>
                            Inside the Vanilla Latex block, <Code>\bullet</Code>{" "}
                            creates a bullet.
                        </ListItem>
                    </OrderedList>
                    We can use this with our editor as so:
                    <CodeBlock code={bulletCode} text={bulletText} />
                    Where the <Code>\bullet</Code> is in a Vanilla Latex block,
                    and <Code>First bullet point</Code> is in the regular
                    editor. Note, this doesn't scale for nested bullets.
                </Box>

                <Heading as="h2" size="lg" mb={2} id="numbered-lists">
                    Numbered Lists
                </Heading>
                <Box mb={6}>
                    Numbered/Lettered lists are currently not natively supported
                    in the Parchmynt editor. However you can mimic them by
                    simply writing:
                    <CodeBlock
                        code={numberedListsCode}
                        shouldBeRendered={false}
                    />
                    Or
                    <CodeBlock
                        code={letteredListsCode}
                        shouldBeRendered={false}
                    />
                    And so on and so forth.
                </Box>
            </GridItem>
        </Grid>
    );
};
