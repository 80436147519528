import { Content } from "./Content";
import { Cursor } from "./Cursor";
import { CursorPosition } from "./CursorPosition";

export class Page {
    public static PAGE_HEIGHT: number = 1123;
    private startPosition: CursorPosition;
    private endPosition: CursorPosition;
    private isDirty: boolean;

    constructor(startPosition: CursorPosition, endPosition: CursorPosition, isDirty: boolean = true) {
        this.startPosition = new CursorPosition(startPosition.getParagraphNumber(), startPosition.getBlockNumber(), startPosition.getOffset());
        this.endPosition = new CursorPosition(endPosition.getParagraphNumber(), endPosition.getBlockNumber(), endPosition.getOffset());
        this.isDirty = isDirty;
    }

    public setStartPosition(startPosition: CursorPosition) {
        this.startPosition.copy(startPosition);
    }

    public setEndPosition(endPosition: CursorPosition) {
        this.endPosition.copy(endPosition);
    }

    public setClean() {
        this.isDirty = false;
    }

    public setDirty() {
        this.isDirty = true;
    }

    public getStartPosition(): CursorPosition {
        return this.startPosition;
    }

    public getEndPosition(): CursorPosition {
        return this.endPosition;
    }

    public getIsDirty(): boolean {
        return this.isDirty;
    }

    /* Rendering Methods */

    // Renders a single page, and doesn't consider overflows in future pages.
    // Assumes that all pages above this are clean and rendered.
    public render(editor: HTMLElement, content: Content, cursor: Cursor, 
        pageIndex: number, remPx: number, blockMap: Map<String, Array<Array<Number>>>
    ): Error | null {
        let pageBuffer = 1 * remPx;

        let start = this.startPosition;
        let end = content.end();

        this.cleanBlockMap(blockMap, [pageIndex, 0, 0, 0]); // remove all coordinates from this page and onwards

        let pageElement = editor.children[pageIndex] as HTMLElement;
        if (pageIndex >= editor.children.length) {
            pageElement = this.createNewPageElement(pageIndex);
            editor.append(pageElement);
        }

        let scrollTo = pageElement.offsetTop + pageElement.offsetHeight - Page.PAGE_HEIGHT/2;
        window.scrollTo(0, scrollTo);

        let paragraphIndex = start.getParagraphNumber();
        let isOverflowing = false;

        let pageContentElement = pageElement.children[0] as HTMLElement;
        pageContentElement.innerHTML = "";
        let paragraphElementIndex = 0;

        while (paragraphIndex < content.getParagraphs().length && !isOverflowing) {
            let paragraph = content.getParagraphs()[paragraphIndex];
            let paragraphElement = null;

            let startBlockNumber = 0;
            let startOffset = 0;
            let chunk = 0;
            let finishedRendering = false;

            if (paragraphIndex == start.getParagraphNumber()) {
                startBlockNumber = start.getBlockNumber();
                startOffset = start.getOffset();
            }

            while (!finishedRendering && !isOverflowing) {
                paragraphElement?.remove();
                chunk++;

                [paragraphElement, finishedRendering] = paragraph.renderChunk(content, cursor, paragraphIndex, startBlockNumber, startOffset, chunk * Content.CHUNK_SIZE);
                if (paragraphElement == null) {
                    paragraphElementIndex--;
                    continue;
                }

                paragraphElement.id = pageIndex + "-" + paragraphElementIndex;

                for (let i = 0; i < paragraphElement.children.length; i++) {
                    let blockElement = paragraphElement.children[i] as HTMLElement;
                    let structure = blockElement.getAttribute("content-structure")!;

                    let coordinates: [number, number, number, number] = [pageIndex, paragraphElementIndex, i, 0];
                    if (startBlockNumber == parseInt(structure.split("-")[1])) {
                        coordinates = [pageIndex, paragraphElementIndex, i, startOffset];
                    }
                    blockElement.id = coordinates.join("-");

                    end = new CursorPosition(paragraphIndex, parseInt(structure.split("-")[1]), startOffset + blockElement.innerText.length);

                    // Cleaning up the block Map
                    this.cleanBlockMap(blockMap, coordinates);
                    if (!blockMap.has(structure)) {
                        blockMap.set(structure, new Array());
                    }
                    blockMap.get(structure)!.push(coordinates);
                }

                pageContentElement.append(paragraphElement);
                isOverflowing = this.isOverflowing(pageContentElement);

                // page is now overflowing.
                if (isOverflowing) {
                    let [splitBlockElement, splitBlockCoordinates, splitBlockOffset] = this.getSplitLocation(pageContentElement, pageBuffer);
                    let splitBlockIndex = splitBlockCoordinates[2];
                    let splitParagraphIndex = splitBlockCoordinates[1];

                    if (pageContentElement.children[splitParagraphIndex].classList.contains("latexparagraph")) {
                        pageContentElement.classList.add("rendererrorborder");
                        return new Error("Latex Paragraph is Overflowing");
                    }

                    if (splitBlockElement!.innerText != "" && splitBlockOffset != splitBlockElement!.innerText.length) {
                        splitBlockElement!.innerHTML = splitBlockElement!.innerHTML.substring(0, splitBlockOffset);
                    }

                    let endOffset = parseInt(splitBlockElement!.id.split("-")[3]) + splitBlockOffset;
                    let structure = splitBlockElement!.getAttribute("content-structure")!;
                    end = new CursorPosition(parseInt(structure.split("-")[0]), parseInt(structure.split("-")[1]), endOffset);

                    for (let i = splitBlockIndex + 1; i < paragraphElement.children.length; i++) {
                        // no block (data structure, not HTML Element) in this loop is rendered on this page or any previous page.
                        paragraphElement.children[i].remove();
                        i--;
                    }

                    for (let i = splitParagraphIndex + 1; i < pageContentElement.children.length; i++) {
                        pageContentElement.children[i].remove();
                        i--;
                    }
                    // incrementing last value of splitBlockCoordinates to delete everything > splitBlockCoordinates (as opposed to >=)
                    splitBlockCoordinates[3]++;
                    this.cleanBlockMap(blockMap, splitBlockCoordinates);
                }
            }
            paragraphIndex++;
            paragraphElementIndex++;
        }
        pageContentElement.classList.remove("rendererrorborder");
        this.setEndPosition(end);
        this.isDirty = false;
        return null;
    }

    public createNewPageElement(pageNumber: number): HTMLElement {
        let pageDiv = document.createElement("div");
        pageDiv.classList.add("page");
        pageDiv.id = String(pageNumber);

        let pageContentDiv = document.createElement("div");
        pageContentDiv.classList.add("pagecontent");
        pageDiv.appendChild(pageContentDiv);
        return pageDiv;
    }

    public isOverflowing(pageContent: HTMLElement): boolean {
        return pageContent.scrollHeight > pageContent.clientHeight;
    }

    public getSplitLocation(pageContent: HTMLElement, buffer: number): [HTMLElement | null, [number, number, number, number], number] {
        let corner = this.getPageContentCorner(pageContent);
        let range = Page.getXYCaretRange(corner.x - buffer, corner.y - buffer);
        let rangeOffset = range!.startOffset;

        /*
        Important Note:

        It seems that, if we are only dealing with text (no Latex Paragraphs or Latex Blocks)
        then the splitLocation Range is always a text node (never a textBlock node or paragraph Node)

        We will have to figure out how to split up latex paragraphs and latex blocks later.

        In addition, in testing this, there are some instances where the range Node is actually the paragraph Node, span node,
        or block Node. However, with the exception of the span node (and that too only for latex), these instances only ocurred when
        the check for overflowing was not on. Make of this what you will.

        We might have to do something like:

        if (range!.startContainer.nodeType == Node.ELEMENT_NODE) { // a <p> or <div>, for example
            // TODO: FIGURE OUT THIS CASE PROPERLY LATER (FOR LATEX)
            return -1;
        }

        */

        // ASSUMPTION: THE RANGE IS FOR A NODE OF TYPE Node.TEXT_NODE
        let splitBlockElement = range!.startContainer.parentElement!.closest(".block")!;
        let splitBlockCoordinates = splitBlockElement.id.split("-").map(c => parseInt(c, 10)) as [number, number, number, number];

        if (splitBlockElement.classList.contains("latexblock")) {
            rangeOffset = 0; // force latex blocks onto the next page (and assume they will always be less than a page in length)
        }

        return [(splitBlockElement as HTMLElement), splitBlockCoordinates, rangeOffset]
    }

    public getPageContentCorner(pageContent: HTMLElement) {
        let rect = pageContent.getBoundingClientRect();
        let bottomRight = { x: rect.right, y: rect.bottom };
        return bottomRight;
    }

    // an amazing function that gets a range object containing
    // accurate offset information based on the cursor position
    public static getXYCaretRange(x: number, y: number): (Range | null) {
        let range = null;

        let element = document.elementFromPoint(x, y);
        let dynamicLatexBlock = element?.closest(".block");
        if (dynamicLatexBlock?.classList.contains("latexblock") && dynamicLatexBlock?.classList.contains("dynamic")) {
            // this is a special case for dynamic latex textblocks in safari. Document.caretPositionFromPoint is inaccurate
            // in safari when you are clicking an active dynamic latex block.
            range = document.createRange();
            range.setStart(element as Node, 0);
            range.collapse(true);
            return range;
        }

        if ((document as any).caretPositionFromPoint) { // standards-based way (supported by firefox)
            let pos = (document as any).caretPositionFromPoint(x, y);
            range = document.createRange();
            range.setStart(pos.offsetNode, pos.offset);
            range.collapse(true);
        } else if (document.caretRangeFromPoint) { // webkit way (supported by all other browsers)
            range = document.caretRangeFromPoint(x, y);
        }
        return range;
    }

    // this method deletes all coordinates from the blockmap that are greater than or equal to the provided maxCoordinates
    public cleanBlockMap(blockMap: Map<String, Array<Array<Number>>>, maxCoordinates: [number, number, number, number]) {
        let maxCoordinatesCP = new CursorPosition(maxCoordinates[0], maxCoordinates[1], maxCoordinates[2]);

        for (let structure of blockMap.keys()) {
            let coordinateList = blockMap.get(structure)!;

            for (let i = 0; i < coordinateList.length; i++) {
                let coordinate = coordinateList[i] as [number, number, number, number];
                let coordinateCP = new CursorPosition(coordinate[0], coordinate[1], coordinate[2]);

                if (maxCoordinatesCP.lessThan(coordinateCP) || (maxCoordinatesCP.equals(coordinateCP) && maxCoordinates[3] <= coordinate[3])) {
                    coordinateList.splice(i, 1);
                    i--;
                }
            }

            if (coordinateList.length == 0) {
                blockMap.delete(structure);
            }
        }
    }
}
